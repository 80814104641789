import Vue from 'vue'
import VueRouter from 'vue-router'
import Xmas from '../views/Xmas.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Xmas',
    component: Xmas
  },
  {
    path: '/statistik',
    name: 'Statistik',
    /* component: Statistik */
    component: () => import( '../views/Statistik.vue') 
  }
]

const router = new VueRouter({
  mode: 'history', 
  base: process.env.BASE_URL,
  routes
})

export default router
