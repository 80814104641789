<template>
   <div class="postkarte"  id="c-postkarte">

      <div style="width: 100%; position: relative;">
         <div class="msg" :style="style_msg" style="position: absolute; visibility: hidden; z-index: -1000; top: 0px; left: 13.5%; width: 42%; height: 12%;">
                <p class="anredename" :style="style_an"><span ref="name" v-html="name" style="border: 1px solid red;"></span></p>
                {{ preview_width }}
         </div> 
        <img alt="Apollo" :src="require(`@/assets/img/template_${motiv}.jpg`)"  width="100%" style="display: block;" />  
        <!-- <img alt="Apollo" :src="require(`@/assets/img/preview_rueck_ohne_alles.jpg`)"  width="100%" style="display: block;" />  -->

         <div class="adressbox" ref="adressbox" style="width: 40%; height: 10%; position: absolute; top: 23.35%; left: 11.7%; overflow: hidden; font-weight: normal;" :style="style_ab" >
         <!-- <div class="adressbox" ref="adressbox" style="width: 40%; height: 10%; position: absolute; top: 25%; left: 11.8%; overflow: hidden; font-weight: normal;" :style="style_ab" > -->
            <template v-if="anrede_post">{{ anrede_post }}<br/></template>
            <span ref="vorname" v-if="errors['vorname'] != ''" class="address-error">Vorname fehlt!</span><span ref="vorname" v-else>{{ vorname }}</span>&nbsp;
            <span ref="nachname" v-if="errors['nachname'] != ''" class="address-error">&nbsp;Nachname fehlt!&nbsp;</span><span ref="nachname" v-else>{{ nachname }}</span>
            <br/>
            <span ref="strasse" v-if="errors['strasse'] != ''" class="address-error">Strasse fehlt!</span><span ref="strasse" v-else>{{ strasse }}</span>&nbsp;
            <span ref="hausnummer" v-if="errors['hausnummer'] != ''" class="address-error">&nbsp;Hausnummer fehlt!&nbsp;</span><span ref="hausnummer" v-else>{{ hausnummer }}</span>
            <br/>
             <span v-if="adresszusatz" ref="adresszusatz" >{{ adresszusatz }}<br/></span><span ref="adresszusatz" v-else></span>
            <span ref="plz" v-if="errors['plz'] != ''" class="address-error">Postleitzahl!</span><span ref="plz" v-else>{{ plz }}</span>&nbsp;
            <span ref="ort" v-if="errors['ort'] != ''" class="address-error">&nbsp;Ort fehlt!&nbsp;</span><span ref="ort" v-else>{{ ort }}</span><br/>
         </div>
<!-- wir haben uns ja lange nicht mehr gesehen. Wie geht es dir? Fährst du über Weihnachten
wieder in die Berge? Vielleicht schaffen wir es ja mal, uns auf einen Glühwein am Weihnachtsmarkt
zu treffen. Würde mich freuen. Bis dahin wünsche ich dir eine schöne Zeit
und viel Freude mit dem Adventskalender. -->
         <div class="textbox" ref="textbox" style="width: 74%; height: 15.5%; position: absolute; top: 34.6%; left: 11.9%;  overflow: hidden; font-weight: normal; transform: rotate(0deg);" :style="style_tb" >
          <!--  <div class="textbox" ref="textbox" style="width: 42%; height: 30.5%; position: absolute; border: 1px solid red; top: 19%; left: 3%; overflow: hidden; font-weight: normal; transform: rotate(-4deg);" :style="style_tb" > -->
            <p class="anredename" :style="style_an"><span ref="anredename" v-html="gruss"></span></p>
          <!--   <p id="msg" class="msg" ref="msg" :style="style_msg" style="border: 1px solid red; display: inline-block;" v-html="msg_html"></p>      -->  
            <p id="msg" class="msg" ref="msg" :style="style_msg"><span v-for="(word, index) in words" style="" :key="index" ref="words" v-html="word + ' '"></span></p>  
         </div>
     
         <transition name="stempel">
            <img alt="Apollo" class="img_stempel" :src="require(`@/assets/img/preview_stempel.png`)"  style="display: block;" v-if="!notsent" :style="style_stempel" />
         </transition>
              
         <transition name="sent-l">
            <img  alt="Apollo" class="img_versendet" :src="require(`@/assets/img/versendet_gruen8.png`)"  style="display: block; z-index: 1000;" v-if="!notsent"  :style="style_versendet" />
         </transition>
      
 </div>

   </div>
</template>

<script>
export default {
   name: "Postkarte",
   props: {
      name: String,
      anrede: {
         type: String,
         default: () => { ''}
      },
      gruss: String,
      anrede_post: {
         type: String,
         default: () => { ''}
      },
      vorname: String,
      nachname: String,
      strasse: String,
      hausnummer: String,
      adresszusatz: String,
      plz: String,
      ort: String,
      msg_html: String,
      msg: String,
      previewVW: Number,
      preview_width: Number,
      notsent: Boolean,
      error: Boolean,
      errors: Object,
      motiv: String
      
   },
   data() {
      return {
         words: [],
         imsg: ''
      }
   },
 methods: {

   },
   watch: {
      msg: function() {
         this.imsg = this.msg.replace( '-', "&#8209;");
         this.imsg= this.msg.replace(/[ ]*\n[ ]*/g, " <br/> ");
         this.words.length = 0;
         this.words = this.imsg.split(" ");
      }
   },
   mounted: function () {
      
   },

   computed: {
      style_msg() {
         const fs = (this.preview_width/54)*1;
         const lh = (this.preview_width/44.9)*1;
         return 'font-size: ' + fs +'px !important; line-height:' + lh +'px !important;' ;
      },
      style_an() {
         const fs = (this.preview_width/30.05)*1;
    
         const lh = (this.preview_width/15.25)*1;
         const pb = (this.preview_width/0)*1;
         return 'font-size: ' + fs +'px !important; line-height:' + lh +'px !important; ' ;
      },
      style_tb() {
         const fs = (this.preview_width/22.2)*1;
   
         const lh = (this.preview_width/22.2)*1;
         const pb = (this.preview_width/0)*1;
         return 'font-size: ' + fs +'px !important; line-height:' + lh +'px !important; ' ;
 
      },
      style_ab() {
         const fs = (this.preview_width/54.8)*1;
         const lh = (this.preview_width/44.8)*1;
         return 'font-size: ' + fs +'px !important; line-height:' + lh +'px !important;' ;
      },
      style_stempel() {
         const b = (this.preview_width/4.8)*1;
         return 'width: ' + b +'px !important;' ;
      },
      style_versendet() {
         const b = (this.preview_width/1.2)*1;
         return 'width: ' + b +'px !important;' ;
      }
   }
};
</script>

<style lang="scss">

// @import url("https://p.typekit.net/p.css?s=1&k=zxs8gfx&ht=tk&f=4774.4859.16558&a=5632900&app=typekit&e=css");

// @font-face {
// font-family:"ff-market-web";
// src:url("https://use.typekit.net/af/6b403f/0000000000000000773599f5/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/6b403f/0000000000000000773599f5/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/6b403f/0000000000000000773599f5/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
// font-display:auto;font-style:normal;font-weight:700;
// }

// @font-face {
// font-family:"ff-market-web";
// src:url("https://use.typekit.net/af/9ee3ad/0000000000000000773599f6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9ee3ad/0000000000000000773599f6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9ee3ad/0000000000000000773599f6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
// font-display:auto;font-style:normal;font-weight:400;
// }

// @font-face {
// font-family:"ff-market-web-pro-condensed";
// src:url("https://use.typekit.net/af/6c6d35/00000000000000003b9b06c3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/6c6d35/00000000000000003b9b06c3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/6c6d35/00000000000000003b9b06c3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
// font-display:auto;font-style:normal;font-weight:500;
// }

.address-error {
   color: red; background-color: rgb(255, 217, 0);padding: 0 3px;
}

.postkarte {
 // font-family: 'ff-market-web', Helvetica, Arial, sans-serif !important;
   font-family: 'Orgon-Medium', Helvetica, Arial, sans-serif !important;
   -moz-hyphens: none !important;
   -o-hyphens: none !important;
   -webkit-hyphens: none !important;
   -ms-hyphens: none !important;
   hyphens: none !important;
   word-wrap: nowrap;
  
}

.adressbox {
   font-family: 'Orgon-Medium', Arial, sans-serif !important;
   hyphens: none;
} 

.anredename {
   -moz-hyphens: none !important;
   -o-hyphens: none !important;
   -webkit-hyphens: none !important;
   -ms-hyphens: none !important;
   hyphens: none !important;
   word-wrap: nowrap;
   overflow: hidden;
   white-space: nowrap;
   font-weight: normal !important;

}

.img_stempel {
   position: absolute;
   top: 16%;
   left: 24%;
   width: 200px;
}

.img_versendet {
   bottom: 10%;
   right: 0%;
   width: 400px;
   transform: rotate(-15deg);
   position: fixed;
   max-width: 400px;
}

.stempel-item-leave-to {
   position: absolute;
}

.stempel-enter-active {
   animation: stempeln 1s;
   animation-timing-function: ease-in-out;
}

.stempel-leave-active {
   animation: stempeln reverse 0.8s;
   animation-timing-function: ease-in-out;
}

.stempel-move {
   transition: transform 1s;
}



.bouncen-item-leave-to {
   position: absolute;
}

.bouncen-active {
   animation: schlitten 2s;
   animation-timing-function: ease-in-out;
}

.bouncen-active {
   animation: schlitten reverse 1.6s;
   animation-timing-function: ease-in-out;
}

.bouncen-move {
   transition: transform 1s;
}


.sent-l-item-leave-to {
   position: relative;
}

.sent-l-enter-active {
   animation: schlitten 2s;
   animation-timing-function: ease-in-out;
}

.sent-l-leave-active {
   animation: schlitten reverse 1.5s;
   animation-timing-function: ease-in-out;
}

.sent-l-move {
   transition: transform 1s;
}


@keyframes stempeln {
   from {
      transform: translate3d(250%, 0, 0) skewX(15deg) scale(1.3);
    
      z-index: 100;
   }

   60% {
      transform: skewX(-15deg) scale(1.3);
   }

   80% {
      transform: skewX(5deg) scale(1.3);; 
   }

   to {
      transform: translate3d(0, 0, 0);
  
      z-index: 100;
   }
}

@keyframes schlitten {
   from {
      transform: translate3d(-1000%, 0, 0)  scale(1.6);
      z-index: 100;
   }

   20% {
      transform: scale(1.6) translate3d(-500%, 0, 0) ;
   }

   80% {
     
   }

   to {
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotate3d(0, 0, 1, -15deg);
   
      z-index: 100;
   }
}

@keyframes zoomInUp {
 
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3) translate3d(0, 500px, 0) rotate3d(0, 0, 1, -15deg);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -15deg);
  }

  40% {
      opacity: 1;
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -15deg);
  }

  60% {
   
    transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, -15deg);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97) rotate3d(0, 0, 1, -15deg);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -15deg);
  }

}

@keyframes bounce-in {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}


</style>

