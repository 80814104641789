
<template>
<div> 

   <Particles v-if="!(isSafari && isOldVersion)"
      id="tsparticles"
      :options="{
         fpsLimit: 30,
         fullScreen: {
         enable: true,
         zIndex: 1
      },
      interactivity: {
         events: {
            onClick: {
            enable: true,
            mode: 'repulse'
            },
            onHover: {
            enable: true,
            mode: 'bubble'
            }
         },
         modes: {
            bubble: {
               distance: 200,
               duration: 0.3,
               opacity: 0.8,
               size: 40
            },
            repulse: {
               distance: 300,
               duration: 0.3,
               factor: 100,
               speed: 1,
               maxSpeed: 50,
               easing: ease-out-quad
            },
            grab: {
               distance: 200,
               links: {
                  opacity: 0.5
               }
            },
            push: {
               particles_nb: 4
            },
            remove: {
               particles_nb: 2
            }
         }
      },
      particles: {
         shape: {
            type: 'image',
            image: {
            src: './img/snow1.png',
            width: 100,
            height: 100
            }
         },
         move: {
            attract: {
            enable: false,
            rotate: {
               x: 300,
               y: 1200
            }
            },
            bounce: false,
            direction: 'bottom',
            enable: true,
            path: {},
            outModes: {
            bottom: 'out',
            left: 'out',
            right: 'out',
            top: 'out'
            },
            spin: {}
         },
         number: {
            density: {
            enable: true,
            value_area: 400
            },
            value: 150
         },
         opacity: {
            random: {
            enable: true
            },
            value: {
            min: 0.2,
            max: 0.8
            },
            animation: {
            speed: 2,
            minimumValue: 0.1
            }
         },
         size: {
            random: {
            enable: true
            },
            value: {
            min: 5,
            max: 15
            },
            animation: {
            speed: 40,
            minimumValue: 0.1
            }
         }
      },
      detectRetina: true,
   }"
   />
   <div id="logo">
      <div class="container">
         <div class="logo">
         <a :href="'https://www.apollo.de'+trackinglink" @click="statistik( 'c_logo');" target="_blank" name="Apollo Website" style="text-decoration: underline; outline: none; color: #fff;">
            <svg class="c-logo__image" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="160.9" height="33.2" viewBox="-201.7 403.9 160.9 33.2" xml:space="preserve">
               <g>
                  <g transform="translate(-117 -41)">
                     <g transform="translate(117 41)">
                        <path class="st0" style="fill:#054f9d" d="M-84.6 410c0 .9-.8 1.7-1.7 1.7-.9 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7 1.7.8 1.7 1.7"></path>
                        <path class="st0" style="fill:#054f9d" d="M-90.1 412.5c0 .8-.6 1.4-1.4 1.4s-1.4-.6-1.4-1.4c0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4"></path>
                        <path class="st0" style="fill:#054f9d" d="M-69.7 410c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-67.1 405.7c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-78.2 420.5c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-73 406.3c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-78.8 407.8c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8c1 .1 1.8.9 1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-74.9 411.4c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-77.8 414.5c.8.6.9 1.8.3 2.5-.6.8-1.8.9-2.6.3s-.9-1.8-.3-2.5c.6-.8 1.8-.9 2.6-.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-80.5 412.5c0 .9-.8 1.7-1.7 1.7-.9 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7c1 0 1.7.7 1.7 1.7"></path>
                        <path class="st0" style="fill:#054f9d" d="M-82.2 416.6c0 .9-.7 1.6-1.6 1.6s-1.6-.7-1.6-1.6c0-.9.7-1.6 1.6-1.6s1.6.7 1.6 1.6"></path>
                        <path class="st0" style="fill:#054f9d" d="M-83 420.5c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5c.9 0 1.5.6 1.5 1.5"></path>
                        <path class="st0" style="fill:#054f9d" d="M-90.7 416.7c0 .6-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2c0-.6.5-1.2 1.2-1.2s1.2.5 1.2 1.2"></path>
                        <path class="st0" style="fill:#054f9d" d="M-94.8 415.6c0 .6-.5 1.1-1.1 1.1s-1.1-.5-1.1-1.1c0-.6.5-1.1 1.1-1.1.6.1 1.1.5 1.1 1.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-90.8 420.5c0 .6-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2c0-.6.5-1.2 1.2-1.2s1.2.5 1.2 1.2"></path>
                        <path class="st0" style="fill:#054f9d" d="M-86.8 418.6c0 .7-.6 1.3-1.3 1.3-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3s1.3.6 1.3 1.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-86.1 414.6c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5 1.5.7 1.5 1.5"></path>
                        <path class="st0" style="fill:#054f9d" d="M-53.2 410c0 .9.8 1.7 1.7 1.7s1.7-.8 1.7-1.7-.8-1.7-1.7-1.7c-1 0-1.7.8-1.7 1.7"></path>
                        <path class="st0" style="fill:#054f9d" d="M-47.8 412.5c0 .8.6 1.4 1.4 1.4.8 0 1.4-.6 1.4-1.4 0-.8-.6-1.4-1.4-1.4s-1.4.6-1.4 1.4"></path>
                        <path class="st0" style="fill:#054f9d" d="M-68.2 410c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.8-1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-59.7 420.5c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.8-1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-64.9 406.3c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8c-.9 0-1.8.8-1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-59.1 407.8c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8c-1 .1-1.8.9-1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-63 411.4c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.8-1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-60 414.5c-.8.6-.9 1.8-.3 2.5.6.8 1.8.9 2.6.3s.9-1.8.3-2.5c-.7-.8-1.9-.9-2.6-.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-57.4 412.5c0 .9.8 1.7 1.7 1.7s1.7-.8 1.7-1.7-.8-1.7-1.7-1.7c-.9 0-1.7.7-1.7 1.7"></path>
                        <path class="st0" style="fill:#054f9d" d="M-55.7 416.6c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6 0-.9-.7-1.6-1.6-1.6-.9-.1-1.6.7-1.6 1.6"></path>
                        <path class="st0" style="fill:#054f9d" d="M-54.9 420.5c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5-1.5.6-1.5 1.5"></path>
                        <path class="st0" style="fill:#054f9d" d="M-47.2 416.7c0 .6.5 1.2 1.2 1.2s1.2-.5 1.2-1.2c0-.6-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2"></path>
                        <path class="st0" style="fill:#054f9d" d="M-43 415.6c0 .6.5 1.1 1.1 1.1s1.1-.5 1.1-1.1c0-.6-.5-1.1-1.1-1.1-.6.1-1.1.5-1.1 1.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-47.1 420.5c0 .6.5 1.2 1.2 1.2s1.2-.5 1.2-1.2c0-.6-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2"></path>
                        <path class="st0" style="fill:#054f9d" d="M-51 418.6c0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3c0-.7-.6-1.3-1.3-1.3-.8 0-1.3.6-1.3 1.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-51.8 414.6c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5-1.5.7-1.5 1.5"></path>
                        <path class="st0" style="fill:#054f9d" d="M-84.6 430.9c0-.9-.8-1.7-1.7-1.7-.9 0-1.7.8-1.7 1.7s.8 1.7 1.7 1.7 1.7-.8 1.7-1.7"></path>
                        <path class="st0" style="fill:#054f9d" d="M-90.1 428.4c0-.8-.6-1.4-1.4-1.4s-1.4.6-1.4 1.4.6 1.4 1.4 1.4 1.4-.6 1.4-1.4"></path>
                        <path class="st0" style="fill:#054f9d" d="M-67.1 435.3c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-73 434.6c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-78.8 433.1c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-77.8 426.4c.8-.6.9-1.8.3-2.5-.6-.8-1.8-.9-2.6-.3-.8.6-.9 1.8-.3 2.5.6.8 1.8 1 2.6.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-82.2 424.3c0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6 1.6.7 1.6 1.6 1.6 1.6-.6 1.6-1.6"></path>
                        <path class="st0" style="fill:#054f9d" d="M-94.8 425.3c0-.6-.5-1.1-1.1-1.1s-1.1.5-1.1 1.1.5 1.1 1.1 1.1 1.1-.5 1.1-1.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-86.8 422.4c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3c.7-.1 1.3-.6 1.3-1.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-53.2 430.9c0-.9.8-1.7 1.7-1.7s1.7.8 1.7 1.7-.8 1.7-1.7 1.7c-1 0-1.7-.8-1.7-1.7"></path>
                        <path class="st0" style="fill:#054f9d" d="M-47.8 428.4c0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4s-.6 1.4-1.4 1.4-1.4-.6-1.4-1.4"></path>
                        <path class="st0" style="fill:#054f9d" d="M-64.9 434.6c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8c-.9 0-1.8-.8-1.8-1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-59.1 433.1c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-60 426.4c-.8-.6-.9-1.8-.3-2.5.6-.8 1.8-.9 2.6-.3.8.6.9 1.8.3 2.5-.7.8-1.9 1-2.6.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-55.7 424.3c0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6c-.9.1-1.6-.6-1.6-1.6"></path>
                        <path class="st0" style="fill:#054f9d" d="M-43 425.3c0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1-.5 1.1-1.1 1.1-1.1-.5-1.1-1.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-51 422.4c0-.7.6-1.3 1.3-1.3s1.3.6 1.3 1.3-.6 1.3-1.3 1.3c-.8-.1-1.3-.6-1.3-1.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-94.8 420.5c0 .6-.5 1.1-1.1 1.1s-1.1-.5-1.1-1.1.5-1.1 1.1-1.1 1.1.5 1.1 1.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-43 420.5c0 .6.5 1.1 1.1 1.1s1.1-.5 1.1-1.1-.5-1.1-1.1-1.1-1.1.5-1.1 1.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-153.1 429.1c-6.8 0-7.7-3.8-7.7-8.6s.9-8.6 7.7-8.6 7.6 3.8 7.6 8.6c.1 4.7-.7 8.6-7.6 8.6m0-14.7c-3.8 0-4 2.2-4 6.1 0 3.8.2 6 4 6s4-2.2 4-6c0-3.9-.2-6.1-4-6.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-135.7 428.1c0 .2-.1.4-.3.5-.3.2-1.2.4-2.6.4-2.5 0-3.9-1.3-3.9-3.7v-19.1c0-.3.2-.5.5-.5l2.6-.5h.1c.3 0 .4.2.4.5V425c0 1.1.7 1.3 1.3 1.3.5 0 .8-.1 1.2-.1.2 0 .3 0 .3.3l.4 1.6z"></path>
                        <path class="st0" style="fill:#054f9d" d="M-126.6 428.1c0 .2-.1.4-.3.5-.3.2-1.2.4-2.6.4-2.5 0-3.9-1.3-3.9-3.7v-19.1c0-.3.2-.5.5-.5l2.6-.5h.1c.3 0 .4.2.4.5V425c0 1.1.7 1.3 1.3 1.3.5 0 .8-.1 1.2-.1.2 0 .3 0 .3.3l.4 1.6z"></path>
                        <path class="st0" style="fill:#054f9d" d="M-117.9 429.1c-6.8 0-7.7-3.8-7.7-8.6s.9-8.6 7.7-8.6 7.6 3.8 7.6 8.6c.1 4.7-.8 8.6-7.6 8.6m0-14.7c-3.8 0-4 2.2-4 6.1 0 3.8.2 6 4 6s4-2.2 4-6c0-3.9-.2-6.1-4-6.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-180.4 428.3c0 .3-.2.3-.4.3h-3.1c-.2 0-.5-.2-.5-.4l-2.9-8.7h-7.6l-2.9 8.7c-.1.2-.3.3-.5.3h-3c-.3 0-.4-.1-.4-.4v-.2l7.7-22.1c.1-.2.4-.4.6-.4h4.7c.2 0 .5.2.6.4l7.6 22.1c.1.3.1.4.1.4zm-10.5-19.9h-.4l-2.7 8.3h5.9l-2.8-8.3z"></path>
                        <path class="st0" style="fill:#054f9d" d="M-170 411.9c-2.1 0-4.5.4-6.3 1-1.5.5-1.9 1-1.9 2.5v19.4c0 .3.2.5.6.5l2.5-.5c.3-.1.5-.2.5-.6v-5.6c1.1.3 2.6.5 4.2.5 5.8 0 7.1-3.2 7.1-8.6 0-5.1-.8-8.6-6.7-8.6m-1.1 14.6c-1.8 0-3.2-.3-3.5-.4v-11c.4-.1 2.4-.6 3.8-.6 2.6 0 3.9.6 3.9 6 0 4.8-1 6-4.2 6"></path>
                        <path class="st1" style="fill:#e9531e" d="M-67.4 413.6c0 .8-.7 1.5-1.5 1.5-.9 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.6 1.5 1.5"></path>
                        <path class="st1" style="fill:#e9531e" d="M-71.9 414c.5.7.3 1.6-.3 2.1-.7.5-1.7.3-2.2-.3-.5-.7-.3-1.6.3-2.1.8-.5 1.7-.4 2.2.3"></path>
                        <path class="st1" style="fill:#e9531e" d="M-75.2 417c.8.3 1.3 1.1 1 1.9s-1.1 1.2-1.9 1c-.8-.3-1.3-1.1-1-1.9.2-.8 1.1-1.3 1.9-1"></path>
                        <path class="st1" style="fill:#e9531e" d="M-66 414c-.5.7-.3 1.6.3 2.1s1.7.3 2.2-.3.3-1.6-.3-2.1c-.8-.5-1.7-.4-2.2.3"></path>
                        <path class="st1" style="fill:#e9531e" d="M-62.7 417c-.8.3-1.3 1.1-1 1.9s1.1 1.2 1.9 1c.8-.3 1.3-1.1 1-1.9-.2-.8-1.1-1.3-1.9-1"></path>
                        <path class="st1" style="fill:#e9531e" d="M-69.7 431c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                        <path class="st1" style="fill:#e9531e" d="M-74.9 429.5c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                        <path class="st1" style="fill:#e9531e" d="M-80.5 428.4c0-.9-.8-1.7-1.7-1.7-.9 0-1.7.8-1.7 1.7s.8 1.7 1.7 1.7c1 0 1.7-.7 1.7-1.7"></path>
                        <path class="st1" style="fill:#e9531e" d="M-90.7 424.3c0-.6-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2c0 .6.5 1.2 1.2 1.2.7-.1 1.2-.6 1.2-1.2"></path>
                        <path class="st1" style="fill:#e9531e" d="M-86.1 426.4c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5.7 1.5 1.5 1.5c.8-.1 1.5-.7 1.5-1.5"></path>
                        <path class="st1" style="fill:#e9531e" d="M-67.4 427.3c0-.8-.7-1.5-1.5-1.5-.9 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5c.8.1 1.5-.6 1.5-1.5"></path>
                        <path class="st1" style="fill:#e9531e" d="M-71.9 426.9c.5-.7.3-1.6-.3-2.1-.7-.5-1.7-.3-2.2.3-.5.7-.3 1.6.3 2.1.8.5 1.7.4 2.2-.3"></path>
                        <path class="st1" style="fill:#e9531e" d="M-75.2 423.9c.8-.3 1.3-1.1 1-1.9-.3-.8-1.1-1.2-1.9-1-.8.3-1.3 1.1-1 1.9.2.8 1.1 1.3 1.9 1"></path>
                        <path class="st1" style="fill:#e9531e" d="M-68.2 431c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8"></path>
                        <path class="st1" style="fill:#e9531e" d="M-63 429.5c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8"></path>
                        <path class="st1" style="fill:#e9531e" d="M-57.4 428.4c0-.9.8-1.7 1.7-1.7s1.7.8 1.7 1.7-.8 1.7-1.7 1.7c-.9 0-1.7-.7-1.7-1.7"></path>
                        <path class="st1" style="fill:#e9531e" d="M-47.2 424.3c0-.6.5-1.2 1.2-1.2s1.2.5 1.2 1.2c0 .6-.5 1.2-1.2 1.2-.7-.1-1.2-.6-1.2-1.2"></path>
                        <path class="st1" style="fill:#e9531e" d="M-51.8 426.4c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5c-.8-.1-1.5-.7-1.5-1.5"></path>
                        <path class="st1" style="fill:#e9531e" d="M-66 426.9c-.5-.7-.3-1.6.3-2.1.7-.5 1.7-.3 2.2.3.5.7.3 1.6-.3 2.1-.8.5-1.7.4-2.2-.3"></path>
                        <path class="st1" style="fill:#e9531e" d="M-62.7 423.9c-.8-.3-1.3-1.1-1-1.9.3-.8 1.1-1.2 1.9-1 .8.3 1.3 1.1 1 1.9-.2.8-1.1 1.3-1.9 1"></path>
                     </g>
                  </g>
               </g>
            </svg>
         </a>
         </div>
         <!--        <img src="@/assets/img/apollo_logo_transparent.png" /> -->
      <div class="counter" v-if="allowed == 1"><h2>Nur noch
         <span>{{ verfuegbar[0] }}</span><span>{{ verfuegbar[1] }}</span><span>{{ verfuegbar[2] }}</span><span>{{ verfuegbar[3] }}</span>
         Stück</h2> </div> 

      </div>
     
   </div>

   <div id="header" style="z-index:999999;">
      <div class="container">
         <div class="box-grusswort">
            <span class="title">Der Adventskalender,<br class="linebreak" /> der Freude schenkt.</span>
            <br /><br /><br />
            <span class="subline"> Mit persönlichem Weihnachtsgruß.</span>
         </div>
         <div class="headerimage">
            <img src="@/assets/img/header.jpg" />
            <div class="box-text">Vom 11.&ndash;17.11. nach Wunsch erstellen und kostenfrei versenden.</div>
         </div>
      </div>
   </div>
   <div id="form" v-if="allowed == 11">
      <div class="formular container"> 
         <div class="col-100">
            <h1>Leider verpasst!</h1><br><br>
            <h2>Unsere Adventskalender-Aktion ist bereits am 17. November zu Ende gegangen.</h2>
         </div>
      </div>
   </div>
         <div id="form" v-if="allowed == 0">
      <div class="formular container">   
         <div class="col-100">
             <h1>Ooops! </h1><br><br>
            <h2>Die Teilnahme an unserer Adventskalender-Aktion ist nur über den Link im Apollo Newsletter möglich. Tipp: Damit Ihnen in Zukunft keine Aktionen und Angebote mehr entgehen, einfach Newsletter abonnieren!</h2>
         </div>
      </div>
   </div>
   <div id="nachfassen" v-if="allowed == 2">
      <div class="formular container">
         <div class="col-100">
            <h2>Oh, Sie sind leider zu spät dran. Alle Adventskalender sind bereits vergriffen. Als kleines Dankeschön für Ihr Interesse hat Apollo eine kleine Überraschung für Sie. Einfach hier Ihre E-Mail-Adresse angeben, Sie hören von uns!</h2>
         </div>
         <div class="col-100" v-if="!error_mailform_stored">
            <div class="row">
               <label for="adresszusatz">E-Mail-Adresse: </label>
               <input type="email" id="email" maxlength="50" :value="email" @input='evt=>email=evt.target.value' placeholder="max@mustermann.de"  />
            </div>
            <span v-if="error_email" class="error-message form-error">{{ error_email }}</span>
         </div>
         <div class="col-100" v-if="!error_mailform_stored">
            <div >
            <input type="checkbox" id="dsgvo" maxlength="50" v-model="dsgvo" />
            <span class="check_datenschutz">Ich bin mit den <span style='text-decoration: underline; cursor: pointer;' @click='showModal = true'>Datenschutzbestimmungen</span> einverstanden.</span>
            </div>
             <span v-if="error_dsgvo" class="error-message form-error">{{ error_dsgvo }}</span>
         </div>
         <div class="col-100">
         <transition name="sent-l">
            <span v-if="error_mailform_stored" class="success-message send-success">Vielen Dank!</span>
         </transition> 
         <transition name="sent-l">
         <button v-if="!error_mailform_stored" class="btn" :class="{ 'wobble': error_mailform }" @click="store_mail()"> 
            <span>{{ btn_store_mail }}</span>
         </button>
          </transition> 
      </div>

      </div>
   </div>
     <div id="newsletter" v-if="allowed == 0">
      <div class="container">          
            <h1>Noch kein Newsletter-Abo? Jetzt anmelden!</h1>
         <div class="col-100">           
            <ul>
               <li>Keine Aktionen mehr verpassen</li>
               <li>5 € Gutschein direkt bei der Newsletter-Anmeldung</li>
               <li>Die topktuellen Trends direkt zu Ihnen ins Postfach</li>
               <li>Immer tolle Angebote entdecken</li>
            </ul>              
         </div>
         <div class="col-100">
            <a :href="'https://www.apollo.de/newsletter'+trackinglink" @click="statistik( 'c_cta_newsletter');" target="_blank" name="Anmelden beim Apollo Newsletter" style="text-decoration: none; outline: none; color: #fff;">
               <button class="btn-newsletter">Newsletter abonnieren</button>
            </a>
         </div>
      </div>
   </div>

   <div id="form" v-if="allowed == 1">
      <div class="motivwahl container" style="z-index: auto;">
         <div class="intro">
            <h1>Wählen Sie Ihr Lieblingsdesign für das Anschreiben:</h1>
         </div>
         <div class="choices">
            <div class="choice">
               <img src="@/assets/img/motivauswahl_1.jpg" width="100%" :class="{'selected' : (motiv == '1')}" />
               <label class="radio-container">
                  <input type="radio" checked="checked" id="1" value="1" v-model="motiv" />
                  <span class="checkmark"></span>
               </label>
            </div>
            <div class="choice">
               <img src="@/assets/img/motivauswahl_2.jpg" width="100%" :class="{'selected' : (motiv == '2')}" />
               <label class="radio-container">
                  <input type="radio" id="2" value="2" v-model="motiv" />
                  <span class="checkmark"></span>
               </label>
            </div>
            <div class="choice">
               <img src="@/assets/img/motivauswahl_3.jpg" width="100%" :class="{'selected' : (motiv == '3')}" />
               <label class="radio-container">
                  <input type="radio" id="3" value="3" v-model="motiv" />
                  <span class="checkmark"></span>
               </label>
            </div>
         </div>
      </div>
      <div class="formular container">
         <h1>Geben Sie Ihren persönlichen Weihnachtsgruß ein:</h1>
         <div class="col-100">
            <label>Bitte wählen:</label>
            <bloom-single-select :options="anreden" noSelection="Anrede" :selected="anrede" :maxOptions="1" @update="anrede = $event;" />
         </div>
         <div class="col-100">
            <label for="name">Name der/des Beschenkten:</label>
            <input type="text" id="name" maxlength="35" v-model="name" placeholder="Name" autocomplete="off" :class="{ error : errors['name']}"  @keydown="checkName( $event)" />
         </div>
         <div class="col-100">
            <label for="msg">Persönlicher Grußtext:</label>
            <textarea id="msg" v-model="msg" @keyup="split" @paste="split" cols="40" rows="7" maxlength="550" placeholder="wir haben uns ja lange nicht mehr gesehen. Wie geht es dir? Fährst du über Weihnachten wieder in die Berge? Vielleicht schaffen wir es ja mal, uns auf einen Glühwein am Weihnachtsmarkt zu treffen. Würde mich freuen. Bis dahin wünsche ich dir eine schöne Zeit und viel Freude mit dem Adventskalender. 

Deine Gisela" />
            <span class="texthinweis" :class="{ 'error': zeilen > 6}" v-if="zeilen > 0">({{ zeilen }}/6 Zeilen)</span><span class="texthinweis error" v-if="zeilen > 6">(max. Zeilenanzahl überschritten!)</span>
         </div>
       
       
         <br/><br/><br/>
         <h1>Adressdaten der Empfängerin/des Empfängers:</h1>
         <div class="col-100 rechtlicher-hinweis">
            Die eingegebenen Daten werden ausschließlich zum Zwecke des Versands der Grußkarte erhoben und verwendet. Nach Abschluss des Postversands werden diese gelöscht.
         </div>
         <div class="col-100">
            <bloom-single-select :options="anreden_post" noSelection="Anrede" :selected="anrede_post" :maxOptions="1" @update="anrede_post = $event;" />
         </div>
         <div class="col-100">
            <div class="col-50">
               <label for="vorname">Vorname:</label>
               <input type="text" id="vorname" maxlength="16" :value='vorname' @input='evt=>vorname=evt.target.value' placeholder="Vorname" :class="{ error : errors['vorname']}"  />
               <span v-if="errors['vorname']" class="error-message form-error">{{ errors['vorname'] }}</span>
            </div>
            <div class="col-50">
               <label for="nachname">Nachname:</label>
               <input type="text" id="nachname" maxlength="26" :value='nachname' @input='evt=>nachname=evt.target.value' placeholder="Nachname"  :class="{ error : errors['nachname'] }"   />
               <span v-if="errors['nachname']" class="error-message  form-error">{{ errors['nachname'] }}</span>
            </div>
         </div>
         <div class="col-100">
            <div class="col-50">
               <label for="strasse">Straße:</label>
               <input type="text" id="strasse" maxlength="30" :value='strasse' @input='evt=>strasse=evt.target.value' placeholder="Straße" :class="{ error : errors['strasse'] }"  />
               <span v-if="errors['strasse']" class="error-message  form-error">{{ errors['strasse'] }}</span>
            </div>
            <div class="col-50">
               <label for="hausnummer">Hausnummer:</label>
               <input type="text" id="hausnummer" maxlength="9" :value='hausnummer' @input='evt=>hausnummer=evt.target.value' placeholder="Hausnummer" :class="{ error : errors['hausnummer'] }"  />
               <span v-if="errors['hausnummer']" class="error-message  form-error">{{ errors['hausnummer'] }}</span>
            </div>
         </div>
         <div class="col-100">
            <label for="adresszusatz">Adresszusatz:</label>
            <input type="text" id="adresszusatz" maxlength="35" :value='adresszusatz' @input='evt=>adresszusatz=evt.target.value' placeholder="Adresszusatz" :class="{ error : errors['adresszusatz']}"  />
         </div>
         <div class="col-100">
            <div class="col-33"> 
               <label for="plz">Postleitzahl:</label>
               <input type="text" id="plz" maxlength="5" :value='plz' @input='evt=>plz=evt.target.value' placeholder="PLZ" :class="{ error : errors['plz'] }" @keyup="fetchPLZ();" />
               <span v-if="errors['plz']" class="error-message form-error">{{ errors['plz'] }}</span>
            </div>
            <div class="col-66">
               <label for="ort">Ort:</label>
               <input type="text" id="ort" maxlength="30" :value='ort' readonly placeholder="Ort" :class="{ error : errors['ort'] }" />
               <span v-if="errors['ort']" class="error-message form-error">{{ errors['ort'] }}</span>
            </div>
         <!--    <div class="col-100 hinweis">
              {{ ab_width['vorname'] }} //   {{ ab_width['nachname'] }} //   {{ ab_width['strasse'] }} //   {{ ab_width['hausnummer'] }} //   {{ ab_width['adresszusatz'] }} //   {{ ab_width['plz'] }} //   {{ ab_width['ort'] }} 
            </div> -->
            <div class="col-100 hinweis">
               Hinweis: Versand nur innerhalb Deutschlands möglich!
            </div>
         </div>
      </div>
   </div>

<div id="action" v-if="allowed == 1">
   <div class="container">
      <h1 :class="{ 'aus': !notsent }" v-if="name == ''">Grußbotschaft nochmal überprüfen, unsere Weihnachtswichtel bringen die Sendung ganz schnell zu Ihrer Wunschadresse!</h1>
      <h1 :class="{ 'aus': !notsent }" v-if="name != ''">Grußbotschaft nochmal überprüfen, unsere Weihnachtswichtel bringen die Sendung ganz schnell zu {{ name }}!</h1>
      <br/>
      <!-- <div class="col-100">
         <h1 class="titel" :class="{ 'aus': !notsent }">Ihr Design:</h1>
           <img alt="Apollo" class="preview_vorderseite" :src="require(`@/assets/img/preview_${motiv}.jpg`)"  width="100%" style="display: block;"   /> 
      </div> -->
      <div class="col-100">
            <h1 class="titel" :class="{ 'aus': !notsent }">Ihre Vorschau:</h1>
            <div id="preview" ref="preview"> 
              <transition name="sent-l">
               <bloom-postkarte ref="postkarte" :error="error" :errors="errors" :motiv="motiv" :notsent="notsent" :preview_width="preview_w" :name="name" :vorname="vorname" :nachname="nachname" :anrede_post="anrede_post_string" :gruss="gruss" :anrede="anreden[anrede]" :msg="msg" :msg_html="msg_html"  :adresszusatz="adresszusatz" :plz="plz" :strasse="strasse" :ort="ort" :hausnummer="hausnummer" />
              </transition>
            </div>

      </div>
      <div class="col-100">
         <transition name="sent-l">
            <span v-if="send_success" class="success-message send-success">{{ send_success }}</span>
         </transition> 
         <button class="btn" :class="{ 'aus': !notsent, 'wobble': error }" @click="send()"> 
<!--            <button class="btn" :class="{ 'wobble': !notsent }" @click="notsent = !notsent">  -->
            <span v-if="!error">Jetzt Kalender und Botschaft kostenfrei versenden!</span><span v-if="error">{{ send_error }}</span>
         </button>
         <span v-if="errors_srv !=''" class="error-message"><h2>Ups! Hier stimmt etwas nicht.</h2><br/>Bitte überprüfen und korrigieren Sie ihre Angaben:<br/><br/><ul class="send-error"><template v-for="field in errors_srv"><li v-for="error in field" :key="error">{{ error }}</li></template></ul></span> 

      </div>

   </div>
</div>

<div id="vorbeischauen">
   <div class="container">
      <h2>Schauen Sie doch mal wieder vorbei:</h2>
         <div class="col-100">  
            <div class="col-50">
               <a :href="'https://filialen.apollo.de'+trackinglink" @click="statistik( 'c_cta_filiale');"  target="_blank" name="Apollo Filialen" style="text-decoration: underline; outline: none; color: #fff;">
                  <button class="btn">
                     In Ihrer Apollo Filiale
                  </button>
               </a>
            </div>         
            <div class="col-50">
               <a :href="'https://www.apollo.de'+trackinglink"  @click="statistik( 'c_cta_apollode');"  target="_blank" name="Apollo Website" style="text-decoration: underline; outline: none; color: #fff;">
                  <button class="btn">
                     Online auf apollo.de
                  </button>
               </a>
               </div>

         </div>
   </div>
</div>

<div id="payback">
   <div class="container">
      <div class="col-100">
      <div class="col-50">
         Sie erhalten für jeden Einkauf bei <span style="white-space: nowrap">Apollo</span> <span style="white-space: nowrap">PAYBACK</span>-Punkte
      </div>
      <div class="col-50">
         <a :href="'https://www.apollo.de/service/apollo-service/payback'+trackinglink" @click="statistik( 'c_payback');" target="_blank" name="Payback mit Apollo" style="text-decoration: none; outline: none;">
         <img src="@/assets/img/payback.png" width="300" class="payback-logo" />
         </a>
      </div>
      </div>
   </div>
</div>

<div style="margin-bottom:-12px"><svg viewBox="0 0 450 29"><path fill="#e4eaef" stroke="#e4eaef" stroke-miterlimit="10" d="m 0.50,18.6 c 0,0 122.6,-34.9 230.0,-7.0 107.6,27.9 219.0,-6.6 219.0,-6.6 V 29.55 H 0.5 Z"></path></svg>
</div>
<div id="disclaimer" >
<div class="container">   
<p style="text-align: left; font-family: sans-serif; font-size: 0.7em; line-height: 1.3em; color: #000; font-weight: normal;">
Internetseite: <a :href="'https://www.apollo.de'+trackinglink" @click="statistik( 'c_apollo.de');" target="_blank" name="Link zu Apollo.de" style="text-decoration: none; outline: none; color: #000;" title="Günstige Brillen bei Apollo - Ihr Augenoptiker">www.apollo.de</a><br>
Datenschutz: <a :href="'https://www.apollo.de/legal/datenschutz'+trackinglink" target="_blank" name="Link zu Apollo.de Datenschutz" style="text-decoration: none; outline: none; color: #000;" title="Apollo Datenschutz">www.apollo.de/legal/datenschutz</a><br/>
Kontakt: <a href="mailto:kundenservicecenter@apollo-optik.com" name="Link Email Feedback Impressum" style="text-decoration: none; outline: none; color: #000;" title="Schreiben Sie uns Ihre Wünsche - wir freuen uns über Ihr Feedback!">kundenservicecenter@apollo-optik.com</a>
<br><br>
Impressum:<br />Anbieter: Apollo-Optik Holding GmbH &amp; Co. KG, Wallenrodstraße 3, 91126 Schwabach.<br />
 <br />
Amtsgericht Nürnberg HRA 14041, Komplementärin: Apollo-Optik Beteiligungs GmbH, Sitz: Schwabach, Amtsgericht Nürnberg HRB 22424, Umsatzsteueridentifikationsnummer DE814565082, Geschäftsführer: Dr. Jörg Ehmer
<br />
Die EU-Kommission hat eine Internetseite zur Online-Streitbeilegung zwischen Unternehmern und Verbrauchern (OS-Plattform) eingerichtet, die Sie unter 
<a href="https://ec.europa.eu/consumers/odr" target="_blank" style="text-decoration: none; outline: none; color: #000; ">https://ec.europa.eu/consumers/odr</a> erreichen.<br/>
Hinweis gemäß Verbraucherstreitbeilegungsgesetz: Die Apollo-Optik Holding GmbH & Co. KG nimmt an einer alternativen Streitbeilegung durch eine Verbraucherschlichtungsstelle nicht teil.
<br/><br/>
<br><br> © Apollo-Optik 2021
</p>
</div>
</div>
    <div class="modal" :class="{ 'show': showModal }">
      <div class="modal-content">
        <div class="close" @click="showModal = false">&times;</div>
         <datenschutz />
      </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import Datenschutz from "../components/Datenschutz"

export default {
   components: { Datenschutz},
     data() {
      return {
         isSafari: false,
         showModal: 0,
         code: '',
         email: '',
         dsgvo: false,
         verfuegbar: '',
         aktiv: 0,
         anrede: -1,
         anrede_post: -1,
         name: '',
         vorname: '',
         nachname: '',
         strasse: '',
         hausnummer: '',
         adresszusatz: '',
         plz: '',
         ort: '',
         motiv: '1',
         msg: '',
         msg_zeilen: [],
         zeilen: 0,
         anreden: [
            'Liebe', 'Liebste', 'Lieber', 'Liebster', 'Hallo'
         ],
         anreden_post: [
             'Keine Anrede', 'Herrn', 'Frau'
         ],
         errors: { 'vorname':'', 'nachname':'', 'plz':'', 'ort':'', 'strasse': '', 'hausnummer': ''},
         error: false,
         errors_srv: '',
         error_sendmail: false,
         success_sendmail: 'Vielen Dank!',
         btn_store_mail: 'Absenden',
         error_mailform: false,
         error_mailform_stored: false,
         error_email: '',
         error_dsgvo: '',
         data: { },
         loaderMsg: '',
         loading: false,
         preview_w : 1,
         notsent: true,
         trackinglink: '',
         validationErrors: '',
         allowed: 1,
         debug: 0,
         response: 'leer',
         faktor: 1,
       //  ab_width: ['vorname','nachname','strasse','hausnummer','adresszusatz','plz','ort']
      };
   },
   watch: {
      msg: function() {
      //   &#8209;  Lorem eiusmod aute esse irure id esse. Dolore voluptate do velit tempor anim. Pariatur exercitation culpa cillum et occaecat amet velit commodo sit consectetur sint dolor amet fugiat.
      
         this.msg = this.msg.replace(/[<>§$^]/g, "");
         this.msg = this.msg.replace(/["´`]/g, "'");
         this.msg = this.msg.replace(/[ ]+/g, " ");
         this.msg = this.msg.replace(/\n(\n)+/g, "\n\n");
         this.msg = this.msg.replace(/[ ]*(\n)[ ]*/g, "\n");
         this.msg = this.msg.replace(/(.*\n.*\n.*\n.*\n.*\n.*)\n.*/g, "$1");
         this.msg = this.msg.replace(/^([ ]*(\n)[ ]*)/g, "");
         this.msg = this.msg.replace(/^(\)\()/g, ") (");
         this.msg = this.msg.replace(/[^a-zA-ZöäüÖÄÜß,-.;:?#!@&() 0-9;|\r|\n]+/, "");
        /*  this.msg = this.msg.replace(/^[ ][\)]+/g, " ("); */
      },
      name: function() {
         
         this.name = this.name.replace(/[^a-zA-ZöäüÖÄÜß,-. ]+/, "");
      },
      vorname: function() {
         this.vorname = this.vorname.substring(0,16);
      //   this.ab_width['vorname'] = this.$refs.postkarte.$refs.vorname.offsetWidth;
         this.vorname = this.vorname.replace(/[^a-zA-ZöäüÖÄÜß,-. ]+/, "");
         this.checkForm('vorname');
      },
      nachname: function() {
         this.nachname = this.nachname.substring(0,26);
      //   this.ab_width['nachname'] = this.$refs.postkarte.$refs.nachname.offsetWidth;
         this.nachname = this.nachname.replace(/[^a-zA-ZöäüÖÄÜß,-. ]+/, "");
         this.checkForm('nachname');
      },
      strasse: function() {
         this.strasse = this.strasse.substring(0,30);
       //  this.ab_width['strasse'] = this.$refs.postkarte.$refs.strasse.offsetWidth;
         this.strasse = this.strasse.replace(/[^a-zA-ZöäüÖÄÜß,-. ]+/, "");
         this.checkForm('strasse');
      },
      adresszusatz: function() {
         this.adresszusatz = this.adresszusatz.substring(0,35);
       //  this.ab_width['adresszusatz'] = this.$refs.postkarte.$refs.adresszusatz.offsetWidth;
         this.adresszusatz = this.adresszusatz.replace(/[^a-zA-ZöäüÖÄÜß,-. 0-9]+/, "");
         this.checkForm('adresszusatz');
      },
      ort: function() {
         this.ort = this.ort.substring(0,30);
        // this.ab_width['ort'] = this.$refs.postkarte.$refs.ort.offsetWidth;
         this.ort = this.ort.replace(/[^a-zA-ZöäüÖÄÜß,-. ]+/, "");
         this.checkForm('ort');
      },
      hausnummer: function() {
         this.hausnummer = this.hausnummer.substring(0,9);
        // this.ab_width['hausnummer'] = this.$refs.postkarte.$refs.hausnummer.offsetWidth;
         this.hausnummer = this.hausnummer.replace(/[^0-9-\/ a-zA-Z]+/, "");
       
         this.checkForm('hausnummer');
      },
      plz: function() {
         this.plz = this.plz.substring(0,5);
       //  this.ab_width['plz'] = this.$refs.postkarte.$refs.plz.offsetWidth;
         this.plz = this.plz.replace(/[^0-9]+/, "");
         this.checkForm('plz');

      },
      email: function() {
         this.email = this.email.substring(0,80);
         this.checkMailForm('email');
      },
      dsgvo: function() {
          this.checkMailForm('dsgvo');
      }
   },
   computed: {
      msg_html() {
         return this.msg.replace(/\n/g, "<br/>")
      },
      link_online_version() {
         return "";
      },
      gruss() {
         var tmp;
         this.anrede >= 0 ? tmp = this.anreden[this.anrede] + ' ' : tmp = '';
         if ( this.name != '' ) { return tmp + this.name + ','; } else return '&nbsp;';
      },
      anrede_post_string() {
         if (this.anrede_post >= 1) { return this.anreden_post[this.anrede_post] } else return '';
      } 
   },
   methods: {
      checkName( event) {
         if (!(/^[a-zA-ZöäüÖÄÜß,-. ]+$/.test(event.key))) return event.preventDefault();


         var name =  this.$refs.postkarte.$refs.name;
         var limit =  ( 560/1050 * this.preview_w);
         var new_width;
         /*   console.log( name.offsetWidth );
                console.log(this.$refs.postkarte.$refs.name.offsetWidth  );
            return event.preventDefault();
               console.log( limit); */
               
         if (name.offsetWidth > limit ) {
            do {
               this.name = this.name.slice(0,-1);
               this.$nextTick( () => {
                  new_width = this.$refs.postkarte.$refs.name.offsetWidth;
               });
            } while ( new_width  > limit); 
            return event.preventDefault();
         } 
      },
      split( event) {
         var words =  this.$refs.postkarte.$refs.words;

         for( var i = 0; i < words.length ; i++) {
            if ( words[i].offsetWidth >  (777/1050 * this.preview_w)) {
               var word = words[i].innerText.trim();
               var word_divided = '';
               const numChunks = Math.ceil(word.length / 43)

               for (let l = 0, p = 0; l < numChunks; ++l, p += 43) {
                  word_divided += word.substr(p, 43) + ' ';
               }
               this.response = word;
               /* this.msg = this.msg.replace(new RegExp( word , 'g'), word_divided); */
               this.msg = this.msg.replace( word , word_divided);
                //  console.log( word_divided + ": " + words[i].offsetWidth  ) 
            }
           
         }
 
         var last_offsetTop = 0;
         this.msg_zeilen.length = 0;

         for( var i = 0; i < 8 ; i++) {
            this.msg_zeilen[i] = '';
         }
         var zeile = 0;
         var lastword = 0;
         for( var i = 0; i < words.length ; i++) {
            if (words[i].offsetTop > last_offsetTop ) { zeile++; if (zeile == 7 ) lastword = i}
            if( words[i].innerHTML != '<br> ' ) this.msg_zeilen[zeile] += words[i].innerText;
            last_offsetTop = words[i].offsetTop;
         };
         for( var i = 0; i <= zeile ; i++) {
            this.msg_zeilen[i] = this.msg_zeilen[i].replace(/([ ]*(<br>)*[ ]*)$/g, "");
       //     console.log( this.msg_zeilen[i]);
         }
       //  console.log( zeile) ;
         if ( this.msg_zeilen[0] == '' ) this.msg_zeilen.shift();

         var msg_cut = '';
         for( var i = 0; i < lastword ; i++) {
            msg_cut += words[i].innerHTML;
         }
         msg_cut = msg_cut.replace( /([ ]*(<br>)+[ ]*)/g, "\n" );
         this.zeilen = zeile;
         if ( this.msg_zeilen[6] != '' || zeile > 6 ) {
            this.msg = msg_cut;
            return event.preventDefault();
         }
      },
   
      checkAlphaString(event) {
       //  console.log( event );
       if (!(/^[a-zA-ZöäüÖÄÜß,-. ]+$/.test(event.key))) return event.preventDefault(); else return true;
      },
      checkAlphaNumString(event) {
       if (!(/^[a-zA-ZöäüÖÄÜß,-. 0-9]+$/.test(event.key))) return event.preventDefault(); else return true;
      },
      checkPLZ(event) {
         if (!(/^[0-9]+$/.test(event.key)) || this.plz.length == 6  ) return event.preventDefault(); else return true;
      },
      fetchPLZ(event) {

         var vm = this;
         if ( this.plz.length == 5 ) {
            axios.get( this.$_APOLLO_API_URL + '/postleitzahl/' + this.plz  )
            .then(function (response) {
                  vm.ort = response.data.ort; 
               if (response.data.ort !== '') {
                  vm.errors['plz'] = '';
                  vm.checkForm('ort');
               } else 
                  vm.errors['plz'] = response.data.error;
            })
            .catch(error => {
               if (error.response.status == 422){
               this.validationErrors = error.response.data.errors;
               }
            })
         }
      
      },
      checkHausnummer(event, val) {
         if (!(/^[0-9-\/ a-zA-Z]+$/.test(event.key) && this.ort.length < 9)) return event.preventDefault();
      },
      checkForm( element ) {

         switch (element) {
            case 'vorname': 
               if (this.vorname) { this.data['vn'] = this.vorname; this.errors['vorname'] = '';} else this.errors['vorname'] = 'Bitte Vorname eingeben';
               break;
            case 'nachname':
               if (this.nachname) { this.data['nn'] = this.nachname; this.errors['nachname'] = ''; } else this.errors['nachname'] = 'Bitte Nachname eingeben';
               break;
            case 'plz':
               if (this.plz && this.plz.length == 5) { this.data['p'] = this.plz; this.errors['plz'] = ''; } else this.errors['plz'] = 'Bitte gültige Postleitzahl eingeben';
               break;
            case 'ort':
               if (this.ort) { this.data['o'] = this.ort;this.errors['ort'] = '';} else this.errors['ort'] = 'Bitte für den Ort eine gültige Postleitzahl eingeben';
               break;
            case 'strasse':
               if (this.strasse) { this.data['s'] = this.strasse; this.errors['strasse'] = ''; } else this.errors['strasse'] = 'Bitte Straße eingeben';
               break;
            case 'hausnummer':
               if (this.hausnummer) { this.data['h'] = this.ort; this.errors['hausnummer'] = '';} else this.errors['hausnummer'] = 'Bitte Hausnummer eingeben';
               break;
            case 'alle':
               if (this.vorname) { this.data['vn'] = this.vorname; this.errors['vorname'] = '';} else this.errors['vorname'] = 'Bitte Vorname eingeben';
               if (this.nachname) { this.data['nn'] = this.nachname; this.errors['nachname'] = ''; } else this.errors['nachname'] = 'Bitte Nachname eingeben';
               if (this.plz && this.plz.length == 5) { this.data['p'] = this.plz; this.errors['plz'] = ''; } else this.errors['plz'] = 'Bitte gültige Postleitzahl eingeben';
               if (this.ort) { this.data['o'] = this.ort;this.errors['ort'] = '';} else this.errors['ort'] = 'Bitte für den Ort eine gültige Postleitzahl eingeben';
               if (this.strasse) { this.data['s'] = this.strasse; this.errors['strasse'] = ''; } else this.errors['strasse'] = 'Bitte Straße eingeben';
               if (this.hausnummer) { this.data['h'] = this.ort; this.errors['hausnummer'] = '';} else this.errors['hausnummer'] = 'Bitte Hausnummer eingeben';
               break;
         }

         this.error = false;
          for (const e in this.errors) { if (this.errors[e] != '') { this.error = true; this.send_error = 'Bitte Adresseingabe überprüfen'; } }

      },
     checkMailForm( element ) {

         switch (element) {
            case 'email': 
               var reg = /^([A-Za-z0-9_\-\.])+\@([A-ZÖÄÜa-zöäü0-9_\-\.])+\.([A-Za-z]{2,4})$/;
               if (reg.test(this.email)) { this.error_email = ''; } else { this.error_email= 'Bitte geben Sie eine gültige E-Mail-Adresse ein';  }
               break;
            case 'dsgvo':
               if (this.dsgvo) { this.error_dsgvo = ''; } else { this.error_dsgvo = 'Bitte bestätigen Sie die Datenschutzbestimmungen';  }
               break;
            case 'alle':
               if (this.dsgvo) { this.error_dsgvo = ''; } else { this.error_dsgvo = 'Bitte bestätigen Sie die Datenschutzbestimmungen';  }
                var reg = /^([A-Za-z0-9_\-\.])+\@([A-ZÖÄÜa-zöäü0-9_\-\.])+\.([A-Za-z]{2,4})$/;
               if (reg.test(this.email)) { this.error_email = ''; } else { this.error_email= 'Bitte geben Sie eine gültige E-Mail-Adresse ein';  }
               break;
               break;
         }

         if (this.error_dsgvo != '' || this.error_email != '') 
            { this.btn_store_mail = 'Bitte überprüfen Sie Ihre Eingaben'; } 
         else 
            { this.btn_store_mail = 'Absenden' }

      },

      send() {

         var vm = this;

         this.checkForm('alle');
         this.errors_srv = '';

         if ( this.error ) { 
            this.send_error = 'Bitte Adresseingabe prüfen';
         } else {

            if ( this.msg != '' ) this.split();

            this.data['m'] = this.motiv;
            this.data['c'] = this.code;
            this.data['z'] = this.adresszusatz;
            this.anrede >= 0 ? this.data['a'] = this.anreden[this.anrede] : this.data['a']= '';
            this.anrede_post >= 1 ? this.data['ap'] = this.anreden_post[this.anrede_post] : this.data['ap']= '';
            this.data['n'] = this.name;
            this.data['vn'] = this.vorname; 
            this.data['nn'] = this.nachname;
            this.data['s'] = this.strasse;
            this.data['h'] = this.hausnummer;
            this.data['p'] = this.plz;
            this.data['o'] = this.ort;
            this.data['msg'] = this.msg;
            this.data['msg1'] = this.msg_zeilen[0];
            this.data['msg2'] = this.msg_zeilen[1];
            this.data['msg3'] = this.msg_zeilen[2];
            this.data['msg4'] = this.msg_zeilen[3];
            this.data['msg5'] = this.msg_zeilen[4];
            this.data['msg6'] = this.msg_zeilen[5];

            axios.post( this.$_APOLLO_API_URL + '/xmas/data', this.data )
               .then(function (response) {  
                  vm.send_error = response.data.error;
                  vm.send_success = response.data.success;
                  vm.notsent = false;
                  vm.response = response.data;
                  vm.verfuegbar = response.data.verfuegbar.toString();
               })
               .catch(error => {
                  if (error.response.status == 422){
                     vm.notsent = true;
                     this.errors_srv = error.response.data.error;
                  }
               })
         }


      },

      store_mail() {

         var vm = this;

         this.checkMailForm('alle');

         if ( !this.error_dsgvo && !this.error_email ) {

            this.data['c'] = this.code;
            this.data['dsgvo'] = this.dsgvo;
            this.data['email'] = this.email;

            axios.post( this.$_APOLLO_API_URL + '/xmas/store_email', this.data )
               .then(function (response) {  
                  vm.error_mailform_store = response.data.error;
                  vm.success_mailform_store = response.data.success;
                  vm.error_mailform_stored = true;
               })
               .catch(error => {
                  if (error.response.status == 422){
                     vm.error_mailform_notstored = true;
                    
                  }
               })
         }


      },

      statistik( event ) {
      
         const data = { "id" : 1};
         data['code'] = this.code;
         if ( event == 'c_logo' ) data['clogo'] = 1;
         if ( event == 'c_cta_apollode' ) data['cctaapollode'] = 1;
         if ( event == 'c_cta_filiale' ) data['cctafiliale'] = 1;
         if ( event == 'c_cta_newsletter' ) data['cctanewsletter'] = 1;
         if ( event == 'c_payback' ) data['cpayback'] = 1;
         if ( event == 'c_aktionen' ) data['caktionen'] = 1;


         axios.post( this.$_APOLLO_API_URL + '/xmas/stats', data )
            .then(function (response) {
               
            })
            .catch(error => {
               if (error.response.status == 422){
               this.validationErrors = error.response.data.errors;
               }
            })
      },
      handleWindowResize(event)  {
         
         this.preview_w = this.$refs.preview.offsetWidth;
         
         this.faktor = (1050 / this.preview_w);

       
     //    console.log( this.preview_w + " / " + this.faktor); 
      }
   },
   mounted() {

      var vm = this

      // let urlParams = new URLSearchParams(window.location.search);

      // if (urlParams.has('d')) this.debug = 1;
      // if (urlParams.has('email')) this.email = urlParams.get('email');
      // if (urlParams.has('c')) this.code = urlParams.get('c').substring(0, 1);

      this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
 
      this.isOldVersion = /^((?!chrome|android).)*version\/1[123]./i.test(navigator.userAgent);

      this.trackinglink = '?utm_source=mktpush&utm_medium=email&utm_campaign=Xmas2021_LP_112021&utm_term=NL_promo&utm_content=Uni';

      this.allowed = 11;

      // switch( this.code ) {
      //    case 'M':  
      //    case 'W':
      //       this.allowed = 1;
      //       break;
      //    case '0':  
      //       this.allowed = 2;
      //       break;
      //    case 'A':
      //       this.allowed = 11;
      //       break;
      //    default:
      //       this.allowed = 0;
            
      // }
   
      // this.statistik( 0 )

      // for( var i = 0; i < 6 ; i++) {
      //    this.msg_zeilen[i] = '';
      // }

      // window.addEventListener('resize', this.handleWindowResize);
      // this.preview_w = this.$refs.preview.offsetWidth; 

      // axios.get( this.$_APOLLO_API_URL + '/xmas/kalender'  )
      // .then(function (response) {
      //    if(response.data.v == '0000' && vm.allowed != 0 ) vm.allowed = 2;  else { vm.verfuegbar = response.data.v; }
      //    if(response.data.g == 1 && vm.allowed != 0 ) vm.allowed = 2;
      //    if(response.data.g == 2 ) vm.allowed = 11;
      // })
      // .catch(error => {
      //    if (error.response.status == 422){
      //    this.validationErrors = error.response.data.errors;
      //    vm.allowed = 0;
      //    }
      // })



   },
   created: function () {
    
   }
};
</script>

<style scoped lang="scss">


.error-message, .success-message, .send-error, .send-success {
   font-weight: normal;
   font-size: 0.8em;
   display: block;
   padding: 10px 0px;
   margin: 0px;

   ul {
       list-style-type: disc;
       margin-left:20px;
   }
}

.texthinweis {
   display: block;
   padding: 10px;
   font-size: 0.8em;
   font-weight: normal;

   &.error  {
      color: red
   }
}

.send-error, .send-success {
   font-size: 1.1em;
   margin: 0 auto;
}

.form-error {
   color: red
}


#logo {
   width: 100%;

   .container {
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 0px;
       

      @media screen and (max-device-width: 600px),
      screen and (max-width: 600px) {
         flex-direction: column;
         justify-content: flex-start;
         align-content: start;
        
      }

      .counter { 
         display: flex;
         justify-content: flex-end;
         align-self: center;
         background-color: $apollo-orange;
         padding: 0.33vw 0.99vw;
         color: white;

         span {
            background-color: black;
            padding: 1px 2px;
            margin: 1px;
         }

         @media screen and (max-device-width: 600px),
            screen and (max-width: 600px) {
            justify-content: end;
            align-self: end;
            margin-bottom: 10px;
            width: 100%;
         }
      }

      .logo {
         display: flex;
         justify-content: flex-start;
         align-self: center;
         padding: 20px 20px;
         color: white;

      img {
         width: 100%;
         height: 100%;
         max-width: 300px;
      }
      }


   }
}


#header {
   width: 100%;
   background-color: $bg-header-periphery;
   margin-bottom: 55px;
   
   .container {
      position: relative;
      background-color: $bg-header;   
      padding: 0px;

      @media screen and (max-device-width: 650px),
      screen and (max-width: 650px) {
        padding-top: 0px;
      }

      @media screen and (max-device-width: 480px),
      screen and (max-width: 480px) {
        padding-top: 0px;
      }
   
      .box-grusswort {
         margin: 0 auto;
         width: 95%;
         text-align: left;
         hyphens: none; 
         color: white;
         background-color: $bg-header;
         padding-top: 30px;
         padding-bottom: 48px;
        

         @media screen and (max-device-width: 1200px),
         screen and (max-width: 1200px) {
            padding-bottom: 4vw;
            padding-top: 2.5vw;
         }

         @media screen and (max-device-width: 600px),
         screen and (max-width: 600px) {
            padding-bottom: 6vw; 
         }

         @media screen and (max-device-width: 400px),
         screen and (max-width: 400px) {
            padding-bottom: 10vw; 
         }

         .linebreak {
            display: none;

            @media screen and (max-device-width: 650px),
            screen and (max-width: 650px) {
               
            }
         }

         .title {
            font-size: 56px;
            line-height: 1em;
            
            @media screen and (max-device-width: 1200px),
            screen and (max-width: 1200px) {
               font-size: 4.17vw;
            }

            @media screen and (max-device-width: 768px),
            screen and (max-width: 768px) {
               font-size: 32px;
            }

            @media screen and (max-device-width: 340px),
            screen and (max-width: 340px) {
               font-size: 28px;
            }
         } 

         .subline {
            font-size: 44px;
            line-height: 1em;

            @media screen and (max-device-width: 1200px),
            screen and (max-width: 1200px) {
               font-size: 3.66vw;
            }

            @media screen and (max-device-width: 768px),
            screen and (max-width: 768px) {
               font-size: 28px;
            }

            @media screen and (max-device-width: 340px),
            screen and (max-width: 340px) {
               font-size: 24px;
            }
         }
      }

      .headerimage {

         position:relative;
         padding-top: 0px;

         @media screen and (max-device-width: 1200px),
         screen and (max-width: 1200px) {
            padding-top: 0px;
         }

         @media screen and (max-device-width: 768px),
         screen and (max-width: 768px) {
            padding-top: calc( 90px - 8vw);
         }


         @media screen and (max-device-width: 480px),
         screen and (max-width: 480px) {
            width: 100%;
         }


         .box-text{
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-items: start;
            align-content: center;
            top:0%;
            left: 2.5%;
            width:45%;
            text-align: left;
            hyphens: none; 
            color: white;
            font-size: 40px;
            line-height: 1.2em;
           

            @media screen and (max-device-width: 1200px),
            screen and (max-width: 1200px) {
               font-size: 3.33vw;
            }

            @media screen and (max-device-width: 768px),
            screen and (max-width: 768px) {
               font-size: 24px;
            }

            @media screen and (max-device-width: 639px),
            screen and (max-width: 639px) {
               top: 0%; 
               bottom: 0%;
            }

            @media screen and (max-device-width: 480px),
            screen and (max-width: 480px) {
               width: 95%;
            }

            @media screen and (max-device-width: 340px),
            screen and (max-width: 340px) {
               font-size: 20px;
            }
         }

         img {
            width: 100%;
            height: 100%;
            display: block;
         }
      }
   }
}


#form {
   width: 100%;
   background-color: $bg-form-periphery;
   margin-bottom: 55px;
   font-size: 1.5em;

   .motivwahl {
      display: flex;
     
      background-color: $bg-form;
      width: 100%;
      flex-direction: row;

     @media screen and (max-device-width: 800px), 
      screen and (max-width: 800px) {
         flex-direction: column;
      } 

      .intro {
         width: 30%;
         text-align: left;
         hyphens: none;


          @media screen and (max-device-width: 900px), 
         screen and (max-width: 900px) {
            width: 35%;
         } 

         @media screen and (max-device-width: 800px), 
         screen and (max-width: 800px) {
            width: 100%;
         }
      }

      .choices {
         display: flex;
         justify-content: space-between;
         width: 70%;
         margin: 0px 28px;

         @media screen and (max-device-width: 900px), 
         screen and (max-width: 900px) {
            width: 65%;
           
         } 

         @media screen and (max-device-width: 800px), 
         screen and (max-width: 800px) {
            width: 100%;
           margin: 20px 0px 0px 0px;
         }

         @media screen and (max-device-width: 450px), 
         screen and (max-width: 450px) {
            flex-direction: column;
         } 


         .choice {
            width: 27%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;

            @media screen and (max-device-width: 450px), 
            screen and (max-width: 450px) {
                width: 75%;
                margin: 0 auto 50px;
              
            }

            img {
              // border: 0px solid #444;
               margin-bottom: 5px;

               .selected {
                  //  border: 10px solid $bg-header !important;
               }
            } 
         }
      }
   }

   .formular {
     
      background-color: $bg-form;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
  
      label {
         display: block;
         width: 100%;
         font-weight: normal;
         margin: 30px 0 10px 0;
      }
      
      textarea, input { 
         width: 100%; 
         margin: 0 !important;
         padding: 10px !important;
      } 

      .col-100 { 
         width: 100%;
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         justify-content: space-between;
         text-align: left;
         padding: 0px;
         margin: 0px;

         &.hinweis {
            font-weight: normal;
            margin-top: 40px;
         }

         &.rechtlicher-hinweis {
            font-weight: normal;
            margin-top: 20px;
            margin-bottom: 30px;
            font-size: 1em;
         }
     
   
         .col-50 {
            width: 47%;
         }

         .col-33 {
            width: 30%;
         }

         .col-66 {
            width: 67%;
         }
        
      
         @media screen and (max-device-width: 599px),
         screen and (max-width: 599px) {
            .col-50, .col-33, .col-66 {
               width: 100%;
            }
         }
      }


   }
}

#action {
   width: 100%;
   background-color: $bg-action-periphery;
   margin-bottom: 55px;
   font-size: 1.5em;

   .container {
      z-index: 1000;
      background-color: $bg-action;
  
      h1 {
         transition: all 0.5s ease-in-out;
         opacity: 1;

         &.aus {
            opacity: 0;
         }
      }

      .col-100 {
         display: flex;
         flex-direction: column;
         justify-content: left;
         text-align: left;
         width: 100%;
         max-width: 1050px;
         padding: 0px;
         margin: 50px 0;

         &.hinweis {
            font-weight: normal;
            margin-top: 0px;
         }

         &.center {
             justify-content: center;
         }

         .titel {
            margin: 20px 0px;
            transition: all 0.5s ease-in-out;
            opacity: 1;

            &.aus {
               opacity: 0;
               display: none;
            }
         }

         a {
            margin: 0px  auto;
            max-width: 550px;
            width: 100%;
         }

         .btn {
            max-width: 550px;
            width: 100%;
            margin: 40px auto;
            padding: 10px;
            border: none;
            border-radius: 1.4em;
            background: $apollo-orange;
            text-align: center;
            text-decoration: none;
            font-size: 1em;
            font-weight: normal;
            color: white;
            cursor: pointer;
            position: relative;

            transition: all 0.5s ease-in-out;
            opacity: 1;

            &.aus {
               opacity: 0;
                display: none;
            }

            &:hover {
               box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
               background: lighten($apollo-orange, 5%);
               transform: scale(1.05);
            }

            &:active {
               background: darken($apollo-orange, 5%);
            }

            &.error {
               background: red;
                
            }


         }

      }
   }
}

#nachfassen {
   width: 100%;
   background-color: $bg-form-periphery;
   margin-bottom: 55px;
   font-size: 1.5em;

   .container {
      z-index: 1000;
      background-color: $bg-form;

      .col-100 {
         display: flex;
         flex-direction:column;
         align-items: flex-start;
         justify-content: center;
         text-align: left;
         width: 100%;
         max-width: 1050px;
         padding: 0px;
         margin: 30px 0;
         font-weight: normal;

                input[type=checkbox] {
               margin-right: 20px; 
               transform: scale(2);
            }

            input[type=email] {
               width: 80%; 
               border: 2px solid #70c7ec;
               margin: 0 !important;
               padding: 10px !important;
               font-size: 1em;
               font-weight: normal;
               resize: none;
            }

            label {
               margin-right: 20px;
            }



            span {
               font-size: 20px;
               line-height: 1.25em;
            }


         .row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: left;
            text-align: left;

             @media screen and (max-device-width: 599px),
            screen and (max-width: 599px) {
               flex-direction:column;
               align-items: start;
               justify-content: center;
            }
         }

         &.hinweis {
            font-weight: normal;
            margin-top: 0px;
         }

         .btn {
            max-width: 550px;
            width: 100%;
            margin: 20px auto;
            padding: 10px;
            border: none;
            border-radius: 1.4em;
            background: $apollo-orange;
            text-align: center;
            text-decoration: none;
            font-size: 1em;
            font-weight: normal;
            color: white;
            cursor: pointer;
            position: relative;

            transition: all 0.5s ease-in-out;
            opacity: 1;

            &.aus {
               opacity: 0;
                display: none;
            }

            &:hover {
               box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
               background: lighten($apollo-orange, 5%);
               transform: scale(1.05);
            }

            &:active {
               background: darken($apollo-orange, 5%);
            }

            &.error {
               background: red;
                
            }


         }

      }
   }
}


#payback {
   width: 100%;
   background-color: $bg-payback-periphery;

   .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $bg-payback;
      font-size: 1.8em;
     /*  flex-wrap: wrap; */
      font-weight: normal;

      .col-100 {
         width: 100%;
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         justify-content: space-between;
         text-align: left;
         padding: 0px;
         margin: 0px;
         align-items: center;
      }

      .col-50 {
         width: 49%;  
         padding: 0px 1% 0px 0px ;
         text-align:left; 
         margin-bottom: 20px;
      

          @media screen and (max-device-width: 700px),
         screen and (max-width: 700px) {
            width: 100%;
            text-align: center;
         } 

         .payback-logo {
            width: 70%;
            max-width: 250px;
         }
      }
   }
}

#vorbeischauen {
   width: 100%;
   background-color: white;
   margin-bottom: 40px;
   font-size: 1.5em;

   .container {
      background-color:  white;
  
      h1, h2 {
         font-weight: bold;
      }
      .col-100 {
         display: flex;
         justify-content: space-between;
         width: 100%; 
         text-align:left; 
         margin: 0px 0px;

         
         a {
            margin: 0px  auto;
            max-width: 550px;
            width: 100%;
         }

         .col-50 {
            width: 48%;
            display: flex;
             justify-content: center;

            .btn {
            max-width: 520px;
            width: 100%;
            margin: 20px auto;
            padding: 10px;
            border: none;
            border-radius: 1.4em;
            background: $apollo-orange;
            text-align: center;
            text-decoration: underline;
            font-size: 1em;
            font-weight: normal;
            color: white;
            cursor: pointer;
            position: relative;

            &:hover {
               box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
               background: lighten($apollo-orange, 5%);
               transform: scale(1.05);
            }

            &:active {
               background: darken($apollo-orange, 5%);
            }

         }
         }

      
         @media screen and (max-device-width: 700px),
         screen and (max-width: 700px) {
            .col-50 {
               width: 100%;
            }

            flex-direction: column;

         }
      
      }
   }
}


#disclaimer {
   width: 100%;
   background-color: $bg-disclaimer-periphery;

   .container {
      display: flex;
      justify-content: left;
      background-color: $bg-disclaimer;
      font-size: 1.4em;
   }
}

#newsletter {
   width: 100%;
   background-color: $bg-form-periphery;
   margin-bottom: 40px;

   .container {
      display: flex;
      flex-direction: column;
      background-color: $bg-form;

      .col-100 {
         display: flex;
         justify-content: space-between;
         width: 100%; 
         text-align:left; 
         margin: 20px auto;

         a {
            margin: 0px  auto;
            max-width: 550px;
            width: 100%;
         }
         
         ul {
            font-size: 1.5em;
            font-weight: normal;
            list-style: url('~@/assets/img/haken25.png');
            margin-left: 30px;
         }

         .col-50 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 48%;  
            margin: 0;


         }
      }


      .btn-newsletter {
         max-width: 550px;
         width: 100%;
         margin: 0 auto;
         height: 2em;
         border: none;
         border-radius: 1.4em;
         border: 1px solid #444;
         background: $white;
         text-align: center;
         text-decoration: none;
         font-size: 1.4em;
 
         font-weight: normal;
         color: #444;
         cursor: pointer;

         &:hover {
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
            transform: scale(1.05);
         }
      }
   }
}


.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000000; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(95, 95, 95, 0.98);

  &.show {
    display: block;
  }
}

/* Modal Content */
.modal-content {
  color: white;
  margin: auto;
  padding: 1.4em;
  border: 0px solid #888;
  width: calc(100% - 2.8em);
  max-width: $max-width-40;
  text-align: left;

  a {
    color: white;
  }
}

/* The Close Button */
.close {
  color: #fff;
  float: right;
  font-size: 2.5em;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


textarea,
input[type="text"] {
   padding: 10px;
   font-size: 1em;
   border: 1px solid #333;
   font-family: "Orgon-Medium", Helvetica, Arial, sans-serif !important;
   font-weight: normal;
   resize: none;
}
/* Customize the label (the container) */
.radio-container {
   display: block;
   position: relative;
   padding-left: 35px;
   margin-bottom: 12px;
   cursor: pointer;
   font-size: 22px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
   position: absolute;
   opacity: 0;
   cursor: pointer;
   height: 0;
   width: 0;
}

/* Create a custom radio button */
.checkmark {
   position: absolute;
   top: 0;
   left: 0;
   height: 25px;
   width: 25px;
   background-color: #c9e8f9;
   border: 1px solid #666;
   border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
   background-color: #a7dffe;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
   background-color: #0d56ba;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
   content: "";
   position: absolute;
   display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
   display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
   top: 6px;
   left: 6px;
   width: 11px;
   height: 11px;
   border-radius: 50%;
   background: #c9e8f9;
}

@media screen and (max-device-width: 1200px), screen and (max-width: 1200px) {
   /* .row {
   width: calc(100% - 40px);
   border-radius: 10px;
   margin: 0 auto;
} */
}

.spinner {
   position: relative;
}

.button--loading::after {
   content: "";

   width: 25px;
   height: 25px;
   margin: auto;
   border: 4px solid transparent;
   border-top-color: #fff;
   border-radius: 50%;
   animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
from {
   transform: rotate(0turn);
}

to {
   transform: rotate(1turn);
}
}

.sent-l-item-leave-to {
   position: relative;
}

.sent-l-enter-active {
   animation: schlitten 1s;
   animation-timing-function: ease-in-out;
}

.sent-l-leave-active {
   animation: schlitten reverse 1s;
   animation-timing-function: ease-in-out;
}

.sent-l-move {
   transition: transform 1s;
}


.stempel-item-leave-to {
   position: absolute;
}

.stempel-enter-active {
   animation: stempeln 1s;
   animation-timing-function: ease-in-out;
}

.stempel-leave-active {
   animation: stempeln reverse 0.8s;
   animation-timing-function: ease-in-out;
}

.stempel-move {
   transition: transform 1s;
}

@keyframes schlitten {
   from {
      opacity: 0;
      z-index: 100;
   }

   to {
      opacity: 1;
      z-index: 100;
   }
}

@keyframes stempeln {
   from {
      transform: translate3d(250%, 0, 0) skewX(15deg) scale(1.3);
      position: absolute;
      z-index: 100;
   }

   60% {
      transform: skewX(-15deg) scale(1.3);
   }

   80% {
      transform: skewX(5deg); 
   }

   to {
      transform: translate3d(0, 0, 0);
      position: absolute;
      z-index: 100;
   }
}

@keyframes zoomInUp {
  from {
    opacity: 0;
  }

  30% {
    opacity: 0;
    transform: scale3d(0.0, 0.0, 0.0) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 0.5;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes wobble {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
.wobble {
  animation: wobble 0.3s;
  transform-origin: center;
}





@import url("https://p.typekit.net/p.css?s=1&k=zxs8gfx&ht=tk&f=4774.4859.16558&a=5632900&app=typekit&e=css");

@font-face {
font-family:"ff-market-web";
src:url("https://use.typekit.net/af/6b403f/0000000000000000773599f5/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/6b403f/0000000000000000773599f5/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/6b403f/0000000000000000773599f5/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"ff-market-web";
src:url("https://use.typekit.net/af/9ee3ad/0000000000000000773599f6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9ee3ad/0000000000000000773599f6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9ee3ad/0000000000000000773599f6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"ff-market-web-pro-condensed";
src:url("https://use.typekit.net/af/6c6d35/00000000000000003b9b06c3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/6c6d35/00000000000000003b9b06c3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/6c6d35/00000000000000003b9b06c3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;
}


.teststring {
  font-family: 'ff-market-web', Helvetica, Arial, sans-serif !important;
  // hyphens: none;
  font-weight: normal;
  background: white;
  font-size:20.8px;
}
</style>
